import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import rightArrowIcon from "../../assets/images/white-right-arrow-btn.svg";
import { useLocation } from "react-router-dom";
import { HandleConsent } from "../../services/api/stream";
import toastify from "shared/toastr";

const ConsentPage = () => {
  const [show, setShow] = useState(true);
  const [params, setParams] = useState(null);
  const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  const location = useLocation();
  const clientId = new URLSearchParams(location.search).get("clientId");
  const redirectUri = new URLSearchParams(location.search).get("redirectUri");
  const userId = new URLSearchParams(location.search).get("userId");
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (clientId && redirectUri && userId) {
      setParams({ clientId, redirectUri, userId });
    }
  }, []);

  const submitConsent = async () => {
    if (params) {
      setisLoading(true);
      await HandleConsent(params).then(
        (res) => {
          if (res.data) {
            const { code } = res.data;
            if (code) {
              handleClose();
              toastify("success", res.message);
              window.location.href = `${redirectUri}?code=${code}`;
            }
          }
          setisLoading(false);
        },
        (error) => {
          toastify("error", error.message);
          setisLoading(false);
        }
      );
    }
  };

  return (
    <div className="confirmation__wrapper">
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        id="confirmation__modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="confirmation__heading">Consent Request</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <p className="mb-0">
            Stream Orders is requesting read access to: Location Data. Do you
            want to Approve access?
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            onClick={handleClose}
            className="cancel-btn red-btn-ghost btn"
          >
            Cancel
          </Button>
          <Button
            onClick={submitConsent}
            disabled={isLoading}
            className="red-btn btn"
          >
            Approve Access
            <span className="icons-right">
              <img src={rightArrowIcon} alt="arrow icon" />
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConsentPage;
