import { privateApi } from "../utilities/Provider";
const basicUrl = "/Settings/";

// Settings API
const addRestaurantSettings = (model) => {
  return privateApi.post(basicUrl + "settings", model);
};

const getDefaultSettings = () => {
  return privateApi.get(basicUrl + "defaultsettings");
};

const getSettingsByKitchenId = (Id) => {
  return privateApi.get(basicUrl + "settings", {
    params: {
      kitchenId: Id,
    },
  });
};

const sendTestOrder = (platformName) => {
  return privateApi.post(basicUrl + "sendtestorder"+'?platformName=' + platformName);
}

export {
  addRestaurantSettings,
  getDefaultSettings,
  getSettingsByKitchenId,
  sendTestOrder,
};
