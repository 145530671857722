import { privateApi } from "../utilities/Provider";
import dateFormat from "dateformat";

const basicUrl = "/order/";
// YYYY-MM-DD
const getOrders = ({
  pageSize,
  pageNum,
  kitchenId,
  serviceId,
  orderStatus,
  deliveryStatus,
  dateTo,
  dateFrom,
  name,
  marketPlaceStatus,
  brandIds
}) => {
  const params = new URLSearchParams();
  if (serviceId) params.append("deliveryServiceId", serviceId);
  if (marketPlaceStatus) params.append("marketPlaceStatus", marketPlaceStatus);

  // if (dateFrom)
  //   params.append("from", dateFormat(new Date(dateFrom), "yyyy-mm-dd"));
  // if (dateTo) params.append("to", dateFormat(new Date(dateTo), "yyyy-mm-dd"));

  if (dateFrom) {
    //let frmtDate = new Date(new Date(dateFrom).toUTCString()).toISOString();
    let frmtDate = dateFormat(new Date(dateFrom), "yyyy-mm-dd");
    params.append("from", frmtDate);
  }
  if (dateTo) {
    //let frmtDate = new Date(new Date(dateTo).toUTCString()).toISOString();
    let frmtDate = dateFormat(new Date(dateTo), "yyyy-mm-dd");
    params.append("to", frmtDate);
  }

  if (pageNum) params.append("pagenum", pageNum);
  if (pageSize) params.append("pagesize", pageSize);
  if (kitchenId) params.append("kitchenId", kitchenId);
  if (name) params.append("searchValue", name);
  if (orderStatus) params.append("orderStatus", orderStatus);
  if (deliveryStatus) params.append("deliveryStatus", deliveryStatus);
  if (brandIds) params.append("brandIds", brandIds);

  return privateApi.get(basicUrl + "orderlisting", { params });
};

const getOrder = (orderId) => {
  const params = new URLSearchParams();
  params.append("orderId", orderId);

  return privateApi.get(basicUrl + "orderdetails", { params });
};

const updateOrderDetails = (model) => {
  return privateApi.put(basicUrl + "waiveoff", model);
};

const changeOrderStatus = (statusId, orderId, kitchenId) => {
  let body = {
    statusId : statusId,
    orderId : orderId,
    kitchenId:kitchenId
  };

  return privateApi.put(basicUrl + "status", body);
};

const updatePrepTime = (time, orderId, kitchenId) => {
  let body = {
    preparationTime : time,
    orderId : orderId,
    kitchenId: kitchenId
  };

  return privateApi.put(basicUrl + "acknowledgement", body);
};

const downloadOrderPayload = (orderId) => {
  const params = new URLSearchParams();
  params.append("orderId", orderId);

  return privateApi.get(basicUrl + "downloadorderpayload", { params });
};


const createCsv = ({
  pageSize,
  pageNum,
  searchFilter,
  kitchenFilter,
  statusFilter,
  deliveryStatusFilter,
  marketplaceFilter,
  dateFromFilter,
  dateToFilter,
  serviceId,
}) => {
  const params = new URLSearchParams();
  if (serviceId) params.append("deliveryServiceId", serviceId);
  if (marketplaceFilter) params.append("marketPlaceStatus", marketplaceFilter);

  // if (dateFrom)
  //   params.append("from", dateFormat(new Date(dateFrom), "yyyy-mm-dd"));
  // if (dateTo) params.append("to", dateFormat(new Date(dateTo), "yyyy-mm-dd"));

  if (dateFromFilter) {
    //let frmtDate = new Date(new Date(dateFromFilter).toUTCString()).toISOString();
    const frmtDate = dateFromFilter
      ? dateFormat(new Date(dateFromFilter), "yyyy-mm-dd")
      : null;
    params.append("from", frmtDate);
  }
  if (dateToFilter) {
    //let frmtDate = new Date(new Date(dateToFilter).toUTCString()).toISOString();
    const frmtDate = dateToFilter
      ? dateFormat(new Date(dateToFilter), "yyyy-mm-dd")
      : null;
    params.append("to", frmtDate);
  }

  if (pageNum) params.append("pagenum", pageNum);
  if (pageSize) params.append("pagesize", pageSize);
  if (kitchenFilter) params.append("kitchenId", kitchenFilter);
  if (searchFilter) params.append("customerName", searchFilter);
  if (statusFilter) params.append("orderStatus", statusFilter);
  if (deliveryStatusFilter) params.append("deliveryStatus", deliveryStatusFilter);

  return privateApi.get(basicUrl + "generateorderhistorycsv", { params });
};

const downloadStatement = ({
  pageSize,
  pageNum,
  searchFilter,
  kitchenFilter,
  statusFilter,
  marketplaceFilter,
  dateFromFilter,
  dateToFilter,
  serviceId,
}) => {
  const params = new URLSearchParams();
  if (serviceId) params.append("deliveryServiceId", serviceId);
  if (marketplaceFilter) params.append("marketPlaceStatus", marketplaceFilter);

  if (dateFromFilter) {
    const frmtDate = dateFromFilter
      ? dateFormat(new Date(dateFromFilter), "yyyy-mm-dd")
      : null;
    params.append("from", frmtDate);
  }
  if (dateToFilter) {
    const frmtDate = dateToFilter
      ? dateFormat(new Date(dateToFilter), "yyyy-mm-dd")
      : null;
    params.append("to", frmtDate);
  }

  if (pageNum) params.append("pagenum", pageNum);
  if (pageSize) params.append("pagesize", pageSize);
  if (kitchenFilter) params.append("kitchenId", kitchenFilter);
  if (searchFilter) params.append("customerName", searchFilter);
  if (statusFilter) params.append("orderStatus", statusFilter);

  return privateApi.get(basicUrl + "downloadstatement", { params });
};

export {
  getOrders,
  getOrder,
  changeOrderStatus,
  updatePrepTime,
  updateOrderDetails,
  createCsv,
  downloadOrderPayload,
  downloadStatement
};
