import React, { useEffect, useState, useContext } from "react";
import dateFormat from "dateformat";
import { useHistory } from "react-router-dom";
import "./OrderView.scss";
import {
  getOrder,
  changeOrderStatus,
  updatePrepTime,
  updateOrderDetails,
} from "services/api/order";
import LoaderBlock from "shared/loader/LoaderBlock";
import OrderStatusFilter from "./OrderStatusFilter";
import toastify from "shared/toastr";
import Timer from "./Timer";
import { Tab, Nav, Col, Row } from "react-bootstrap";
import StepsIndicator from "./StepsIndicator";
import EditButton from "UI/Button/EditButton";
import UserContext from "store/user-context";
import { RoleType } from "constants/roleType";
import waiveOffDescImg from "assets/images/waiveoff-descicon.svg";
import deleteIcon from "assets/images/delete-icon.svg";
import DeleteModal from "UI/Modal/DeleteModal";
import downloadIcon from "assets/images/order-download-icon.svg";
import { downloadOrderPayload } from "services/api/order";
import orderDetailsRgIcon from "assets/images/order-recipeguide-icon.svg";
import {
  OrderStatusLabel,
  OrderStatus,
  screenNames,
  DriverStatus,
} from "constants/orderStatus";
import OrderTrackingModal from "./OrderTrackingModal";
import OrdersNotificationContext from "store/orders-notification-context";
import closeButton from "assets/images/modal-close-white-icon.svg";
import closeButtonGrey from "assets/images/modal-close-icon.svg";

const OrderDetails = (props) => {
  const history = useHistory();
  const OrdersNotificationCtx = useContext(OrdersNotificationContext);
  const data = props?.data;
  const orderId = OrdersNotificationCtx.drawerState?.orderId || props?.data?.id;
  const [isLoading, setisLoading] = useState(false);
  const [order, setOrder] = useState(data);
  const [activeStep, setactiveStep] = useState(1);
  const [isEdit, setisEdit] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);
  const [totalWaiveOffAmt, settotalWaiveOffAmt] = useState(data?.orderTransaction?.waiveOffAmount || 0);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteType, setDeleteType] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [deliveryDispatchStatus, setDeliveryDispatchStatus] = useState(null);
  const [showDeliveryDispatchStatus, setShowDeliveryDispatchStatus] = useState(true);
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;
  const {
    deliveryJobRequired,
    expectedAt,
    deliveryJobId,
    orderTransaction,
    trackingUrl,
  } = order || {};
  const latestOrderStatus = OrdersNotificationCtx?.updatedOrderDetails;
  const [orderStatusId, setOrderStatusId] = useState(data?.orderStatusId || null);
  const [pickupEta, setPickupEta] = useState(data?.pickupEta || null);
  const [dropOffEta, setDropOffEta] = useState(data?.dropOffEta || null);
  const [orderStatusChronology, setOrderStatusChronology] = useState(data?.orderStatusChronology && data?.orderStatusChronology?.length > 0 ? data.orderStatusChronology.reverse() : []);
  const tip = orderTransaction?.actualTip || orderTransaction?.tip || orderTransaction?.defaultTip || null;
  const deliveryCharges = orderTransaction?.actualDeliveryCharge || orderTransaction?.deliveryCharge || null;
  const [displayStatus, setDisplayStatus] = useState(null);

  useEffect(() => {
    if (orderId && parseInt(orderId) > 0) {
      getDetail(+orderId);
    }
    return () => {
      setIsMounted(false);
    };
  }, [orderId]);

  useEffect(() => {
    if (latestOrderStatus && latestOrderStatus?.orderId === +orderId) {
      setOrderStatusId(latestOrderStatus?.statusId);
      setPickupEta(latestOrderStatus?.pickUpEta);
      setDropOffEta(latestOrderStatus?.dropOffEta);
      updateStatus(
        latestOrderStatus?.statusId,
        latestOrderStatus?.deliveryStatus,
        latestOrderStatus?.modifiedAt,
        latestOrderStatus?.deliveryStatusModifiedAt
      );;
      updateChronology(latestOrderStatus);
    }
  }, [latestOrderStatus]);

  useEffect(() => {
    if (orderStatusChronology && orderStatusChronology?.length > 0) {
      const description = orderStatusChronology.find(
        (item) => item.orderStatusId === 91
      )?.description;
      if (description) {
        const {
          DRIVERREQUESTED,
          DRIVERASSIGNED,
          DRIVERENROUTE,
          DRIVERARRIVED,
          PICKUPCOMPLETE,
          PICKUPENROUTE,
          DROPOFFCOMPLETE,
        } = DriverStatus;
        const statusIds = [
          DRIVERREQUESTED,
          DRIVERASSIGNED,
          DRIVERENROUTE,
          DRIVERARRIVED,
          PICKUPCOMPLETE,
          PICKUPENROUTE,
          DROPOFFCOMPLETE,
        ];
        const isIdMatched = orderStatusChronology.some((item) =>
          statusIds.includes(item.orderStatusId)
        );
        setShowDeliveryDispatchStatus(!isIdMatched);
        const code = description.match(/code:(.*?),/)?.[1].trim();
        const message = description.match(/message:(.*?)}/)?.[1];
        setDeliveryDispatchStatus({ code, message });
      } else {
        setDeliveryDispatchStatus(null);
      }
    }
  }, [orderStatusChronology]);

  const updateStatus = (
    orderStatus,
    deliveryStatus,
    modifiedAt,
    deliveryStatusModifiedAt
  ) => {
    const statusId =
      deliveryStatus > 0 &&
      (deliveryStatus === DriverStatus.DROPOFFCOMPLETE ||
        deliveryStatus === DriverStatus.DELIVERYJOBCREATED)
        ? orderStatus
        : deliveryStatusModifiedAt > modifiedAt
        ? deliveryStatus
        : orderStatus;     
    setDisplayStatus(statusId);
  };

  const updateChronology = (data) => {
    const orderStatusId = data?.deliveryStatus
      ? data?.deliveryStatus
      : data?.statusId;
    const updatedChronology = [
      { ...data, orderStatusId },
      ...orderStatusChronology,
    ];
    const uniqueChronology = updatedChronology.filter((item, index) => {
      const currentIndex = updatedChronology.findIndex(
        (obj) =>
          obj["orderStatusId"] === item["orderStatusId"] &&
          obj["modifiedAt"] === item["modifiedAt"] &&
          obj["orderStatusId"] !== DriverStatus.COURIERLOCATIONUPDATED
      );
      return currentIndex === index;
    });
    setOrderStatusChronology(uniqueChronology);
  };

  const onBack = () => {
    history.goBack();
  };

  const getDetail = async (id) => {
    setisLoading(true);
    await getOrder(id)
      .then((res) => {
        let data = res.data;
        if (isMounted) {
          setOrder(data);
          setOrderStatusId(data?.orderStatusId);
          updateStatus(
            data?.orderStatusId,
            data?.deliveryStatusId,
            data?.modifiedAt,
            data?.deliveryStatusModifiedAt
          );
          setPickupEta(data?.pickupEta);
          setDropOffEta(data?.dropOffEta);
          setOrderStatusChronology(
            data?.orderStatusChronology?.reverse() || []
          );
          settotalWaiveOffAmt(data?.orderTransaction?.waiveOffAmount);
          setisLoading(false);
        }
      })
      .catch(() => {
        setOrder(null);
        setisLoading(false);
        onBack();
      });
  };

  const getStatus = (statusId) => {
    return OrderStatusLabel.find((it) => it.value === statusId);
  };

  const formatPhoneNumber = (phoneStr) => {
    const regex = /[^0-9]/g;
    const phoneNo = phoneStr.replace(regex, "");
    const length = phoneNo.length;
    const countryCode = "+" + phoneNo.slice(0, length - 10) + " ";
    const number =
      phoneNo.slice(length - 10, length - 7) +
      "-" +
      phoneNo.slice(length - 7, length - 4) +
      "-" +
      phoneNo.slice(length - 4, length);
    return length > 10 ? countryCode + number : number;
  };

  const onChangeOrderStatus = async (statusId, orderId, kitchenId) => {
    setisLoading(true);
    await changeOrderStatus(statusId, orderId, kitchenId)
      .then((res) => {
        getDetail(orderId);
        setisLoading(false);
        toastify("success", res.message);
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  const updatePreparationTime = async (time, statusId, orderId, kitchenId) => {
    setisLoading(true);
    await updatePrepTime(time, orderId, kitchenId)
      .then((res) => {
        onChangeOrderStatus(statusId, orderId, kitchenId);
        setisLoading(false);
        toastify("success", res.message);
      })
      .catch(() => {
        setisLoading(false);
      });
  };

  const onTabChange = (step) => {
    setactiveStep(step);
  };

  const updateMenuItemInputField = (item) => {
    const newOrder =
      order?.orderItems &&
      order?.orderItems?.length &&
      order?.orderItems.map((obj) => {
        if (obj.id === item.id) {
          return { ...obj, isEdit: true };
        }
        return obj;
      });
    setOrder({
      ...order,
      orderItems: newOrder,
    });
  };

  const updateModOptionInputField = (modOption) => {
    const newOrder =
      order?.orderItems &&
      order?.orderItems?.length &&
      order?.orderItems.map((item) => {
        item?.orderItemMod &&
          item?.orderItemMod?.length &&
          item?.orderItemMod.map((obj) => {
            if (obj.id === modOption.id) {
              obj["isEdit"] = true;
            }
          });
        return item;
      });
    setOrder({
      ...order,
      orderItems: newOrder,
    });
  };

  const updateInputFields = (evt, item, type, blur) => {
    const regex = /^\d+\.\d{3}$/;
    let value = evt.target.value;
    let newOrder = [];
    if (value) {
      setisDisabled(false);
    } else {
      setisDisabled(true);
    }
    if (type === "menuItem" || type === "menuItemPrice") {
      newOrder =
        order?.orderItems &&
        order?.orderItems?.length &&
        order?.orderItems.map((obj) => {
          if (obj.id === item.id) {
            if (type === "menuItem") {
              obj["waiveOffDescription"] = value;
              obj["descError"] = "";
            } else {
              obj["waiveOffAmount"] = value ? value : 0;
              regex.test(value) &&
                (obj["waiveOffAmount"] = parseFloat(value).toFixed(2));
              blur && (obj["waiveOffAmount"] = parseFloat(value));
              obj["amtError"] = "";
            }
          }
          return obj;
        });
    } else {
      newOrder =
        order?.orderItems &&
        order?.orderItems?.length &&
        order?.orderItems.map((ele) => {
          ele?.orderItemMod &&
            ele?.orderItemMod?.length &&
            ele?.orderItemMod.map((obj) => {
              if (obj.id === item.id) {
                if (type === "modOpt") {
                  obj["waiveOffDescription"] = value;
                  obj["descError"] = "";
                } else {
                  obj["waiveOffAmount"] = value ? value : 0;
                  regex.test(value) &&
                    (obj["waiveOffAmount"] = parseFloat(value).toFixed(2));
                  blur && (obj["waiveOffAmount"] = parseFloat(value));
                  obj["amtError"] = "";
                }
              }
            });
          return ele;
        });
    }
    setOrder({
      ...order,
      orderItems: newOrder,
    });
  };

  const deleteInputFields = async () => {
    let newOrder = [];
    const item = deleteItem;
    const type = deleteType;
    let totalWaiveOff = 0;
    newOrder =
      order?.orderItems &&
      order?.orderItems?.length &&
      order?.orderItems.map((ele) => {
        if (type === "menuItem" && ele.id === item.id) {
          ele["isEdit"] = false;
          ele["waiveOffAmount"] = 0;
          ele["waiveOffDescription"] = "";
        }
        if (ele?.waiveOffAmount) {
          totalWaiveOff = totalWaiveOff + ele?.waiveOffAmount;
        }
        ele?.orderItemMod &&
          ele?.orderItemMod?.length &&
          ele?.orderItemMod.map((obj) => {
            if (type === "modOpt" && obj.id === item.id) {
              obj["isEdit"] = false;
              obj["waiveOffAmount"] = 0;
              obj["waiveOffDescription"] = "";
            }
            if (obj?.waiveOffAmount) {
              totalWaiveOff = totalWaiveOff + obj?.waiveOffAmount;
            }
          });
        return ele;
      });
    settotalWaiveOffAmt(totalWaiveOff);
    setOrder({
      ...order,
      orderItems: newOrder,
    });
    setDeleteLoading(true);
    const model = {
      orderId: order?.id,
      waiveOffAmount: totalWaiveOff,
      waiveOffDescription: "",
      orderItems: newOrder,
    };
    await updateOrderDetails(model)
      .then((res) => {
        setDeleteLoading(false);
        setIsDelete(false);
        toastify("success", res?.message);
      })
      .catch(() => setDeleteLoading(false));
  };

  const onSubmit = async () => {
    const isValid = validate();
    if (isValid === true) {
      setisLoading(true);
      const totalWaiveOffAmt = updateTotalWaiveOffAmount();
      const model = {
        orderId: order?.id,
        userRole: userRole,
        userName: userCtx?.userInfo?.username || "",
        waiveOffAmount: totalWaiveOffAmt,
        waiveOffDescription: "",
        orderItems: order?.orderItems,
      };
      await updateOrderDetails(model)
        .then((res) => {
          setisEdit(false);
          setisLoading(false);
          toastify("success", res?.message);
        })
        .catch(() => {
          setisLoading(false);
        });
    }
  };

  const onCancel = () => {
    setisEdit(false);
    getDetail(order.id);
  };

  const updateTotalWaiveOffAmount = () => {
    let totalWaiveOffAmt = 0;
    order?.orderItems &&
      order?.orderItems?.length &&
      order?.orderItems.map((ele) => {
        ele?.orderItemMod &&
          ele?.orderItemMod?.length &&
          ele?.orderItemMod.map((obj) => {
            if (obj?.waiveOffAmount) {
              totalWaiveOffAmt = totalWaiveOffAmt + obj?.waiveOffAmount;
            }
          });
        if (ele?.waiveOffAmount) {
          totalWaiveOffAmt = totalWaiveOffAmt + ele?.waiveOffAmount;
        }
      });
    settotalWaiveOffAmt(totalWaiveOffAmt);
    return totalWaiveOffAmt;
  };

  const validate = () => {
    let flag = true;
    const descErrTxt = "Please enter Description";
    const amtErrTxt = "Please enter Amt";
    const newOrder = order?.orderItems.map((ele) => {
      ele?.orderItemMod &&
        ele?.orderItemMod?.length &&
        ele?.orderItemMod.map((obj) => {
          if (
            /* obj.isEdit === true  */ isEdit === true &&
            (obj.isEdit === true ||
              obj.waiveOffAmount > 0 ||
              obj.waiveOffDescription)
          ) {
            if (
              obj?.waiveOffDescription === "" ||
              obj?.waiveOffDescription === null ||
              !obj?.waiveOffDescription
            ) {
              obj["descError"] = descErrTxt;
              flag = false;
            }
            if (
              obj?.waiveOffAmount === null ||
              obj?.waiveOffAmount === "" ||
              !obj?.waiveOffAmount
            ) {
              obj["amtError"] = amtErrTxt;
              flag = false;
            }
          }
        });
      if (
        isEdit === true &&
        (ele.isEdit === true ||
          ele.waiveOffAmount > 0 ||
          ele.waiveOffDescription)
      ) {
        if (
          ele?.waiveOffDescription === "" ||
          ele?.waiveOffDescription === null ||
          !ele?.waiveOffDescription
        ) {
          ele["descError"] = descErrTxt;
          flag = false;
        }
        if (
          ele.waiveOffAmount === null ||
          ele.waiveOffAmount === "" ||
          !ele?.waiveOffAmount
        ) {
          ele["amtError"] = amtErrTxt;
          flag = false;
        }
      }
      return ele;
    });
    setOrder({
      ...order,
      orderItems: newOrder,
    });
    return flag;
  };

  const filterModifierNames = (modifiers) => {
    const values = Array.from(
      new Set(
        modifiers.map((element) => {
          return element.menuItemModName;
        })
      )
    );
    return values;
  };

  const displayModifiers = (modifiers, index, numbers) => {
    if (modifiers.length) {
      const filteredModifierNames = filterModifierNames(modifiers);
      return (
        <>
          {filteredModifierNames.map((modeName) => {
            const modifierNames = modifiers.filter(
              (item) => item["menuItemModName"] === modeName
            );
            return (
              <>
                {modeName && (
                  <label className="text-capitalize">{modeName}</label>
                )}
                {modifierNames.map((jkr, idx) => {
                  return showModifiers(jkr, numbers);
                })}
              </>
            );
          })}
        </>
      );
    }
  };

  const showModifiers = (mod, idx, numbers) => {
    return (
      <>
        <div key={idx} className="item-modifiers">
          <span>
            1x {mod.title}
            {isEdit === true &&
            !mod.waiveOffAmount &&
            !mod.waiveOffDescription ? (
              <EditButton onClick={() => updateModOptionInputField(mod)} />
            ) : null}
          </span>
          <span>${mod.price?.toFixed(2)}</span>
        </div>
        {isEdit === true &&
          (mod.isEdit === true ||
            mod.waiveOffAmount > 0 ||
            mod.waiveOffDescription) && (
            <div className="inputClass justify-content-between d-flex mb-2">
              <div>
                <input
                  key={idx}
                  type="text"
                  placeholder="e.g. Item Missing"
                  className={"inputTextField px-3"}
                  onChange={(e) => updateInputFields(e, mod, "modOpt")}
                  value={mod.waiveOffDescription}
                />
                {mod?.descError && (
                  <div className="invalid-feedback d-block">
                    {mod?.descError}
                  </div>
                )}
              </div>
              <div className="d-flex mr-0">
                <div>
                  <span className="currency-code">$</span>
                  <input
                    key={idx}
                    type="number"
                    placeholder="0.00"
                    min={0}
                    className={"inputPriceField"}
                    onBlur={(e) =>
                      updateInputFields(e, mod, "modOptPrice", true)
                    }
                    onChange={(e) => updateInputFields(e, mod, "modOptPrice")}
                    value={mod.waiveOffAmount || ""}
                  />
                  {mod?.amtError && (
                    <div className="invalid-feedback d-block text-wrap">
                      {mod?.amtError}
                    </div>
                  )}
                </div>
                {isEdit === true &&
                  (mod.isEdit === true ||
                    mod.waiveOffAmount > 0 ||
                    mod.waiveOffDescription) && (
                    <button
                      type="button"
                      onClick={() => {
                        setIsDelete(true);
                        setDeleteType("modOpt");
                        setDeleteItem(mod);
                      }}
                      className="deleteitem_icon"
                    >
                      <span>
                        <img
                          src={deleteIcon}
                          className="icons mr-0"
                          alt="remove"
                        />
                      </span>
                    </button>
                  )}
              </div>
            </div>
          )}
        {isEdit === false &&
          (mod.waiveOffAmount > 0 || mod.waiveOffDescription) && (
            <div key={idx} className="item-modifiers">
              <span className="errorCharges">
                {" "}
                {<img src={waiveOffDescImg} />} {mod.waiveOffDescription}
              </span>
              <span className="errorCharges">
                ${mod.waiveOffAmount?.toFixed(2)}
              </span>
            </div>
          )}
        {mod.comment && <span>Notes: {mod.comment}</span>}
      </>
    );
  };

  const downloadOrderData = async () => {
    setisLoading(true);
    if (orderId) {
      await downloadOrderPayload(orderId)
        .then((res) => {
          res["TransactionDetail"]["ActualTip"] = orderTransaction?.actualTip;
          res["TransactionDetail"]["ActualDeliveryCharge"] =
            orderTransaction?.actualDeliveryCharge;
          setisLoading(false);
          const response = JSON.stringify(res, null, 2);
          const fileType = "text/json";
          const fileName = `${orderId}.json`;
          const blob = new Blob([response], { type: fileType });
          const a = document.createElement("a");
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();
          toastify("success", "Success");
        })
        .catch(() => {
          setisLoading(false);
          toastify("error", "Resource Not Found!");
        });
    }
  };

  const openTrackingPage = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onViewLink = (recipeGuideId) => {
    const vbId = order?.virtualBrand?.id;
    history.push(`/recipeguide/${vbId}/${recipeGuideId}#view`);
  };

  const getDriverStatus = () => {
    const pickUpDriverEta = deliveryJobRequired ? pickupEta : expectedAt;
    const noEtaLabel = deliveryJobRequired ? "Pending" : "NA";
    return pickUpDriverEta ? (
      <Timer
        time={order.modifiedAt}
        pickUpTime={pickUpDriverEta}
        screens={screenNames.orderDetails}
        timeOffset={order?.timeOffset}
      />
    ) : (
      <div className="status-text">
        <span></span>
        <span>{noEtaLabel}</span>
      </div>
    );
  };

  const getPickUpEta = () => {
    let driverEta = expectedAt;
    if (deliveryJobRequired) {
      driverEta =
        orderStatusId === OrderStatus.PREPARING ||
        orderStatusId === OrderStatus.READY
          ? pickupEta
          : orderStatusId === OrderStatus.COMPLETED
          ? dropOffEta
          : null;
    }
    const noEtaLabel = deliveryJobRequired ? "Pending" : "NA";
    return driverEta
      ? dateFormat(new Date(driverEta), "mmm dd yyyy, hh:MM TT")
      : noEtaLabel;
  };

  const canEditAdjustment = () => {
    const today = new Date().getDate();
    const orderDate = new Date(order?.orderDate).getDate();
    const isAdminEdit = [RoleType.ADMIN, RoleType.ADMIN_STAFF].includes(
      userRole
    );
    const isAdjustmentEdit = ([
      RoleType.KITCHEN_OWNER,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ].includes(userRole) &&
      (orderDate === today ||
        ((orderDate !== today) &&
          orderStatusId &&
          ![OrderStatus.COMPLETED, OrderStatus.CANCELLED].includes(
            orderStatusId
          ))));
      return (
        activeStep === 1 &&
        isEdit === false &&
        (isAdminEdit || isAdjustmentEdit) && (
            <EditButton
              onClick={() => setisEdit(true)}
              disabled={order.isPayoutRestricted}
            />
        )
      );
  };

  return (
    <div className="order__details__wrapper">
      <div className="detailsCard__wrapper">
        {isLoading ? (
          <div className="py-5">
            <LoaderBlock />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12 no-gutters">
                <div
                  className={`order-details-body-header ${
                    getStatus(displayStatus)?.colourClass
                  }`}
                >
                  <div className="col-md-5 col-8 order__status__flex pl-0">
                    <button
                      type="button"
                      className="btn__no__style"
                      onClick={props.onHide}
                    >
                      <img src={closeButton} alt="close button" />
                    </button>
                    <div className="status-text">
                      <span>Status</span>
                      <span>{getStatus(displayStatus)?.label}</span>
                    </div>
                    {orderStatusId === OrderStatus.PREPARING ? (
                      <div className="status-text no__wrap">
                        <span>Est. Arrival</span>
                        <div>{getDriverStatus()}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-7 col-4">
                    <div className="orderstatus__wrapper">
                      <OrderStatusFilter
                        onChangeOrderStatus={onChangeOrderStatus}
                        updatePreparationTime={updatePreparationTime}
                        onReload={getDetail}
                        orderDetails={order}
                        orderStatusId={orderStatusId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-12 col-md-5 border__right">
                <div className="order-details-body-details">
                  <div className="body-heading">
                    <h2 style={{ whiteSpace: "normal" }}>
                      {order.virtualBrand.name}{" "}
                      {order?.isSharedVB && `- ${order.kitchen.name}`}
                    </h2>
                  </div>
                  <div className="order-detail-card">
                    <label>Order Date</label>
                    <p>
                      {dateFormat(
                        new Date(order.orderDate),
                        "mmm dd yyyy, hh:MM TT"
                      )}
                    </p>
                  </div>
                  <div className="order-detail-card">
                    <label>Est. Pickup Time</label>
                    <p>{getPickUpEta()}</p>
                  </div>
                  <div className="order-detail-card">
                    <label>Est. Prep Time</label>
                    <p>
                      {order.acknowledgedAt
                        ? dateFormat(
                            new Date(
                              new Date(order.acknowledgedAt).getTime() +
                                order.preparationTime * 60000
                            ),
                            "mmm dd yyyy, hh:MM TT"
                          )
                        : "Pending"}
                    </p>
                  </div>
                  <div className="order-detail-card">
                    <label>Order Type</label>
                    <p>{`${order.serviceType.name} - ${
                      order.isFuture ? "Future" : "ASAP"
                    }`}</p>
                  </div>
                  <div className="order-detail-card">
                    <label>Delivery by</label>
                    <p>{order?.deliveryBy || order.deliveryService.name}</p>
                  </div>
                  <div className="order-detail-card">
                    <label>Customer Notes</label>
                    <p>
                      {order?.specialInstructions ||
                        "No special instructions are available for this order."}
                    </p>
                  </div>
                  <div className="order-detail-card">
                    <label>Delivery Address</label>
                    <p>
                      {order.customer ? (
                        <>
                          {order.customer?.name ? order.customer.name : null}
                          {order.customer?.address1 ? (
                            <div>{order.customer.address1}</div>
                          ) : null}
                          {order.customer?.address2
                            ? order.customer.address2
                            : null}
                          {order.customer?.city ? (
                            <div>
                              {order.customer?.city +
                                ", " +
                                order.customer?.state +
                                " " +
                                order.customer?.zipCode}
                            </div>
                          ) : null}
                          {order.customer?.phone ? (
                            <div>
                              {formatPhoneNumber(order.customer?.phone)}
                            </div>
                          ) : null}
                        </>
                      ) : (
                        "No Customer information is available for this order"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-7">
                <div className="order__details__action">
                  {deliveryJobId && (
                    <button
                      onClick={() => openTrackingPage()}
                      className="track__btn"
                    >
                      TRACK
                    </button>
                  )}
                  {[RoleType.ADMIN].includes(userRole) && (
                    <div className="download-icon">
                      <button
                        type="button"
                        className="btn_new edit-btn m-0"
                        onClick={() => downloadOrderData()}
                      >
                        <span>
                          <img
                            src={downloadIcon}
                            alt="download"
                            //width={30}
                            //height={30}
                          />
                        </span>
                      </button>
                    </div>
                  )}
                </div>

                {showDeliveryDispatchStatus &&
                  [RoleType.ADMIN, RoleType.ADMIN_STAFF].includes(userRole) &&
                  deliveryDispatchStatus && (
                    <div className="order-detail-error-card mt-4">
                      <div className="alert alert-danger m-0 p-2 position-relative">
                        <button
                          type="button"
                          className="btn__no__style close_btn_grey"
                          onClick={() => {
                            setShowDeliveryDispatchStatus(false);
                          }}
                        >
                          <img src={closeButtonGrey} alt="close button" />
                        </button>
                        <label>{deliveryDispatchStatus.code}</label>
                        <p>{deliveryDispatchStatus.message}</p>
                      </div>
                    </div>
                  )}

                <div className="logo__area">
                  <div className="img-box">
                    <img
                      src={order.deliveryService.logoUrl}
                      alt="brand-logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="content-box">
                    <h3>
                      {order.orderItems?.length || 0} Item(s) for
                      {" " + order.customer?.name}
                    </h3>
                    <span className="order-no">Order- #{order.id}</span>
                  </div>
                </div>

                <div className="w-100">
                  <Tab.Container
                    id="addMenu__item__modal__Tabs"
                    defaultActiveKey="first"
                    mountOnEnter
                    unmountOnExit
                  >
                    <Row className="no-gutters">
                      <Col sm={12}>
                        <Nav
                          variant="tabs"
                          className="justify-content-around addMenu__item__modal__Tabs"
                        >
                          <Nav.Item className="text-center mr-0 text-uppercase">
                            <Nav.Link
                              eventKey="first"
                              active={activeStep === 1}
                              onClick={() => onTabChange(1)}
                              className="mr-0"
                            >
                              Order Details
                              {canEditAdjustment()}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="text-center text-uppercase">
                            <Nav.Link
                              eventKey="second"
                              active={activeStep === 2}
                              onClick={() => onTabChange(2)}
                            >
                              Status
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                    </Row>
                    <Row className="no-gutters">
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane
                            eventKey="first"
                            active={activeStep === 1}
                            mountOnEnter
                            unmountOnExit
                          >
                            <div className="col-12">
                              <div className="items__price__breakups">
                                <div className="main__heading"></div>
                                {order.orderItems &&
                                order.orderItems?.length ? (
                                  order.orderItems.map((item, idx) => (
                                    <div key={idx} className="item__block">
                                      <div className="item">
                                        <span style={{ whiteSpace: "normal" }}>
                                          {item.quantity}x {item.title}
                                          {item?.isRecipeGuideAvailable && (
                                            <img
                                              src={orderDetailsRgIcon}
                                              className="icons cursor__pointer ml-1 mr-0"
                                              alt="remove"
                                              onClick={() =>
                                                onViewLink(item?.recipeGuideId)
                                              }
                                            />
                                          )}
                                          {isEdit === true &&
                                          /* item.isEdit !== true */ !item.waiveOffAmount ? (
                                            <EditButton
                                              onClick={() =>
                                                updateMenuItemInputField(item)
                                              }
                                            />
                                          ) : null}
                                        </span>
                                        <span>
                                          $
                                          {(
                                            item.quantity * item.price
                                          )?.toFixed(2)}
                                        </span>
                                      </div>
                                      {isEdit === true &&
                                        (item.isEdit === true ||
                                          item.waiveOffAmount > 0 ||
                                          item.waiveOffDescription) && (
                                          <div className="d-flex inputClass mb-2 justify-content-between">
                                            <div>
                                              <input
                                                key={idx}
                                                type="text"
                                                placeholder="e.g. Item Missing"
                                                className={
                                                  "inputTextField px-3"
                                                }
                                                onChange={(e) =>
                                                  updateInputFields(
                                                    e,
                                                    item,
                                                    "menuItem"
                                                  )
                                                }
                                                value={item.waiveOffDescription}
                                              />
                                              {item?.descError && (
                                                <div className="invalid-feedback d-block">
                                                  {item?.descError}
                                                </div>
                                              )}
                                            </div>
                                            <div className="d-flex">
                                              <div>
                                                <span className="currency-code">
                                                  $
                                                </span>
                                                <input
                                                  key={idx}
                                                  type="number"
                                                  placeholder="0.00"
                                                  min={0}
                                                  className={"inputPriceField"}
                                                  onBlur={(e) =>
                                                    updateInputFields(
                                                      e,
                                                      item,
                                                      "menuItemPrice",
                                                      true
                                                    )
                                                  }
                                                  onChange={(e) =>
                                                    updateInputFields(
                                                      e,
                                                      item,
                                                      "menuItemPrice"
                                                    )
                                                  }
                                                  value={
                                                    item.waiveOffAmount || ""
                                                  }
                                                />
                                                {item?.amtError && (
                                                  <div className="invalid-feedback d-block text-wrap">
                                                    {item?.amtError}
                                                  </div>
                                                )}
                                              </div>
                                              {isEdit === true &&
                                                (item.isEdit === true ||
                                                  item.waiveOffAmount > 0 ||
                                                  item.waiveOffDescription) && (
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      setIsDelete(true);
                                                      setDeleteType("menuItem");
                                                      setDeleteItem(item);
                                                    }}
                                                    className="deleteitem_icon"
                                                  >
                                                    <span>
                                                      <img
                                                        src={deleteIcon}
                                                        className="icons mr-0"
                                                        alt="remove"
                                                      />
                                                    </span>
                                                  </button>
                                                )}
                                            </div>
                                          </div>
                                        )}
                                      {isEdit === false &&
                                        (item.waiveOffAmount > 0 ||
                                          item.waiveOffDescription) && (
                                          <div className="item errorCharges mt-0 pt-0">
                                            <span className="errorCharges">
                                              {<img src={waiveOffDescImg} />}{" "}
                                              {item.waiveOffDescription}
                                            </span>
                                            <span className="errorCharges">
                                              ${item.waiveOffAmount?.toFixed(2)}
                                            </span>
                                          </div>
                                        )}
                                      {item.orderItemMod &&
                                      item.orderItemMod?.length ? (
                                        <>
                                          <div className="item__modifiers__wrapper">
                                            <>
                                              {displayModifiers(
                                                item?.orderItemMod,
                                                idx,
                                                item?.quantity
                                              )}
                                            </>
                                          </div>
                                        </>
                                      ) : null}
                                      {item.notes && (
                                        <div className="item__notes">
                                          <div>
                                            <label>Notes</label>
                                          </div>
                                          <p style={{ whiteSpace: "normal" }}>
                                            {item.notes}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  ))
                                ) : (
                                  <>
                                    <h5>No Order(s)</h5>
                                  </>
                                )}
                                <div className="item__block">
                                  <div className="item pb-0">
                                    <span>Tax</span>
                                    <span>
                                      $
                                      {order.orderTransaction.salesTax?.toFixed(
                                        2
                                      )}
                                    </span>
                                  </div>
                                  <div className="item pb-0">
                                    <span>Adjustments</span>
                                    <span
                                      className={
                                        totalWaiveOffAmt > 0
                                          ? "positive__error__charges"
                                          : "errorCharges"
                                      }
                                    >
                                      $ {totalWaiveOffAmt?.toFixed(2)}
                                    </span>
                                  </div>
                                  <div className="item pb-0">
                                    <span>Total Amount</span>
                                    <span>
                                      $
                                      {(
                                        order.orderTransaction.subTotal +
                                        totalWaiveOffAmt
                                      )?.toFixed(2)}
                                    </span>
                                  </div>
                                  {tip !== null && tip > 0 && (
                                    <div className="item pb-0">
                                      <span>Tip</span>
                                      <span>${tip?.toFixed(2)}</span>
                                    </div>
                                  )}
                                  {deliveryCharges !== null &&
                                    deliveryCharges > 0 && (
                                      <div className="item">
                                        <span>Delivery Fee</span>
                                        <span>
                                          ${deliveryCharges?.toFixed(2)}
                                        </span>
                                      </div>
                                    )}
                                </div>
                                <div
                                  className="btnClass"
                                  style={{
                                    display: isEdit === true ? "block" : "none",
                                  }}
                                >
                                  <button
                                    type="submit"
                                    onClick={onSubmit}
                                    disabled={
                                      isDisabled === true ? true : false
                                    }
                                    className="red-btn saveBtn"
                                  >
                                    Save Adjustments
                                  </button>
                                  <button
                                    type="button"
                                    onClick={onCancel}
                                    className="red-btn-ghost cancelBtn"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="second"
                            active={activeStep === 2}
                            mountOnEnter
                            unmountOnExit
                          >
                            <div className="items__price__breakups">
                              <div className="main__heading"></div>
                              <div className="body-overflow">
                                <div className="wrapper">
                                  <ul className="StepProgress">
                                    <StepsIndicator
                                      steps={orderStatusChronology}
                                      data={order}
                                    />
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
            {isDelete && (
              <DeleteModal
                show={isDelete}
                title={"Request to Delete Adjustments"}
                subtitle="Are you sure you want to remove this adjustment. The actual amount will reflect here once you delete the 
          adjustments."
                onCancel={() => setIsDelete(false)}
                onSubmit={deleteInputFields}
                onLoading={deleteLoading}
                buttonText={"Yes, Delete"}
              />
            )}

            {isOpen && (
              <OrderTrackingModal
                show={isOpen}
                className="right tracking"
                onHide={closeModal}
                deliveryJobId={deliveryJobId}
                trackingUrl={trackingUrl}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
