import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import exportIcon from "assets/images/export-icon.svg";
import filterIcon from "assets/images/Icon_Filter_ckc.svg";
import emptyIocn from "assets/images/emptyIcon.svg";
import backArrowIcon from "assets/images/back-arrow.svg";
import "./Reports.scss";
import SummarySection from "./SummarySection";
import { getReportData } from "services/api/report";
import { downloadStatement } from "services/api/order";
import LoaderInline from "shared/loader/LoaderInline";
import ChartSection from "./ChartSection";
import Subheader from "layout/non-auth-layout/sub-header/Subheader";
import downloadCsv from 'download-csv';
import dateFormat from "dateformat";
import UserContext from "store/user-context";
import { RoleType } from "constants/roleType";
import { ServiceId } from "constants/serviceType";
import { numberFormat } from "constants/numberFormat";
import OrderFilterModal from "../order/listing/OrderFilterModal";
import { getActiveKitchens } from "services/api/kitchen";
import OrdersNotificationContext from "store/orders-notification-context";
import downloadIcon from "assets/images/order-download-icon.svg";
import ExportCsv from "pages/order/listing/ExportCsv";
import toastify from "shared/toastr";
import SelectedFilters from "../../components/filters/SelectedFilters";
import LoaderBlock from "shared/loader/LoaderBlock";

const ReportLayout = () => {
  const [isLoading, setisLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [filterToggle, setFilterToggle] = useState(false);
  const userCtx = useContext(UserContext);
  const userRole = userCtx.userRole;
  const [isOpen, setIsOpen] = useState(false);
  const OrdersNotificationCtx = useContext(OrdersNotificationContext);
  const useFilters = OrdersNotificationCtx?.reportsFiltersData;
  const [selectedFilters, setSelectedFilters] = useState(
    OrdersNotificationCtx?.reportsFiltersData
  );
  const selectedBrandId = useFilters?.selectedBrandId;
  const dateTo = useFilters?.dateTo;
  const dateFrom = useFilters?.dateFrom;
  const marketPlaceStatus = useFilters?.marketPlaceStatus;
  const orderStatus = useFilters?.orderStatus;
  const [selectedKitchen, setSelectedKitchen] = useState(null);
  const [kitchenId, setKitchenId] = useState(0);
  const selectedKitchenId = useFilters?.selectedKitchenId;
  const [totalRecords, settotalRecords] = useState(0);
  const pageSize = 350;

   const getKitchensForDD = async () => {
     await getActiveKitchens().then((res) => {
       let data = res.data;
       if (data && data.length) {
         if (
           [RoleType.KITCHEN_OWNER, RoleType.KITCHEN_STAFF].includes(userRole)
         ) {
           setSelectedKitchen(data[0]?.name);
           setKitchenId(data[0]?.id);
         }
       }
     });
   };
  
  const getListing = async () => {
    let masks = {
      kitchenId: selectedKitchenId || kitchenId,
      virtualBrandId: selectedBrandId,
      dateTo: dateTo,
      dateFrom: dateFrom,
      orderStatus: orderStatus,
      marketPlaceStatus: marketPlaceStatus,
    };
    setisLoading(true);
    await getReportData(masks)
      .then((res) => {
        let data = res.data;
        setReportData(data);
        setFilterToggle(false);
        setisLoading(false);
      })
      .catch(() => {
        setReportData(null);
        setFilterToggle(false);
        setisLoading(false);
      });
  };

  useEffect(() => {
    if ([RoleType.KITCHEN_OWNER, RoleType.KITCHEN_STAFF].includes(userRole)) {
      getKitchensForDD();
    }
   }, [userRole]);

  useEffect(() => {
      getListing();
  }, [useFilters, selectedFilters, kitchenId]);


  const exportReports = () => {
    const revenueData = reportData?.revenueData;
    const reportDeliveryPartnerRevenueData = reportData?.reportDeliveryPartnerRevenueData;
    const revenueDatawithDeliveryPartnerData = [{ ...revenueData }, ...reportDeliveryPartnerRevenueData];
    const datas = [];
    let KitchenName = ![RoleType.ADMIN, RoleType.ADMIN_STAFF].includes(userRole) ? selectedKitchen : revenueData?.kitchen
    let kitchenVal = JSON.stringify(KitchenName).split(",").join(" ");
    revenueDatawithDeliveryPartnerData.map((item) => {
      const obj = {
        platformName: item?.platformName || 'All',
        kitchen: kitchenVal,
        revenueShare: `"${numberFormat(item?.revenueShare)}"`,
        // totalOrders: `"${numberFormat(item?.totalOrders, false)}"`,
        completedOrdersCount: `"${numberFormat(item?.completedOrdersCount, false)}"`,
        averageValue: `"${numberFormat(item?.averageValue)}"`,
        totalRevenue: `"${numberFormat(item?.totalRevenue)}"`,
        bhbCommission: `"${numberFormat(item?.deliveryBHBCommission + item?.pickUpBHBCommission)}"`,
        // taxes: `"${numberFormat(item?.taxes)}"`,
        errorCharges: `"${numberFormat(item?.totalWaiveOffAmount)}"`,
        // errorRate: `${item?.totalOrdersCountWithWaiveOffChanges && item?.totalOrders ? (item?.totalOrdersCountWithWaiveOffChanges * 100 / item?.totalOrders).toFixed(2) : 0}%`,
        tip: `"${numberFormat(item?.tips)}"`,
        deliveryCharge: `"${numberFormat(item?.deliveryPartnerCommission)}"`,
        actualTip: `"${numberFormat(item?.actualTip)}"`,
        actualDeliveryCharge: `"${numberFormat(item?.actualDeliveryCharge)}"`,
        /* newCustomersCount: item?.newCustomersCount,
        existingCustomersCount: item?.existingCustomersCount */
      };
      datas.push(obj);
    })
    const columns = {
      platformName: 'Delivery Partner',
      kitchen: 'Kitchen',
      revenueShare: 'Revenue Share',
      // totalOrders: 'Total Orders',
      // Rename Total Completed Orders to Total Orders
      completedOrdersCount: 'Total Orders',
      averageValue: 'Average Order Value',
      totalRevenue: 'Total Sales',
      bhbCommission: 'Backhouse Brands Commission',
      // taxes: 'Taxes',
      errorCharges: 'Error Charges',
      // errorRate: 'Error Rate',
      tip: 'Tip',
      deliveryCharge: 'Delivery Charge',
      actualTip: 'Actual Tip',
      actualDeliveryCharge: 'Actual DeliveryCharge'
      /* newCustomersCount: 'New Users',
      existingCustomersCount: 'Returning Users' */
    };
    const fileName = 'ReportsSummary' + dateFormat(new Date(), "yyyy-mm-dd, hh:MM:ss");
    downloadCsv(datas, columns, `${fileName}.csv`);
  };

  const exportStatementCSV = async () => {
    await downloadStatement({
      pageSize: pageSize,
      pageNum: 1,
      searchFilter: null,
      kitchenFilter: selectedKitchenId,
      statusFilter: 4,
      marketplaceFilter: marketPlaceStatus,
      dateFromFilter: dateFrom,
      dateToFilter: dateTo,
    })
      .then((res) => {
        let data = res.data;
        if (data) {
          window.open(data, "_self");
          toastify("success", "Success");
        }
      })
      .catch(() => {
        toastify("error", "Export CSV Failed");
      });
  };

  const onReportsFilterToggle = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onChangeSelection = (filters) => {
    setSelectedFilters({
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
      selectedTimeRange: filters.selectedTimeRange,
      marketPlaceStatus: filters.marketPlaceStatus,
      selectedKitchenId: filters.selectedKitchenId,
      selectedBrandId: filters.selectedBrandId
    });
  }

  return (
    <>
      <Subheader>
        <div className="goBack mb-3">
          <Link
            to="/dashboard"
            className="back-btn-red"
            title="Go to Dashboard"
          >
            <span className="icons">
              <img src={backArrowIcon} alt="back icon" />
            </span>
            Go to Dashboard
          </Link>
        </div>
      </Subheader>
      <div className="reports__wrapper">
        <div className="orders__page__wrapper">
          <div className="managermodifier__wrapper basic__info__content">
            <div className="container">
              <div className="managermodifier__header">
                <div className="row justify-content-between">
                  <div className="col-12 mb-3 pb-3 d-none d-md-block">
                    <div className="goBack">
                      <Link
                        to="/dashboard"
                        className="back-btn-red"
                        title="back to home"
                      >
                        <span className="icons">
                          <img src={backArrowIcon} alt="back icon" />
                        </span>
                        Go to Dashboard
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 mb-4 d-flex justify-content-between">
                    <h1>Report Summary</h1>
                    <div className="d-none d-xl-flex align-items-center">
                      {/* begin:: filters for web screens */}
                      <div className="filters__wrapper__mobile">
                        <div className="filters__toggle__mobile">
                          <button
                            type="button"
                            onClick={onReportsFilterToggle}
                            className={
                              "filters orders__filter__icon " +
                              (selectedKitchenId ||
                              selectedBrandId ||
                              dateTo ||
                              dateFrom
                                ? "filter__applied"
                                : "")
                            }
                          >
                            <span>
                              <img
                                src={filterIcon}
                                alt="export icon"
                                className="img-fluid"
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="export__filters__wrapper ml-3">
                        <div className="export__wrapper">
                          <button
                            className="export-btn btn"
                            onClick={exportStatementCSV}
                          >
                            <span>
                              <img
                                src={exportIcon}
                                alt="export icon"
                                className="img-fluid icons"
                              />
                            </span>
                            <span>Statement</span>
                          </button>
                        </div>
                      </div>
                      {/* {(userRole === "Admin" ||
                        userRole === "Restaurant Owner") && (
                        <div className="ml-3">
                          <ExportCsv
                            searchFilter={null}
                            kitchenFilter={selectedKitchenId}
                            statusFilter={orderStatus}
                            marketplaceFilter={marketPlaceStatus}
                            dateFromFilter={dateFrom}
                            dateToFilter={dateTo}
                            pageSize={exportsPageSize}
                            totalRecords={totalRecords}
                          />
                        </div>
                      )} */}
                      {/* <div className="ml-3">
                        <button
                          type="button"
                          className="btn_new btn__no__style"
                          onClick={() => downloadStatement()}
                        >
                          <span>
                            <img
                              src={downloadIcon}
                              alt="download"
                              width={35}
                              height={48}
                            />
                          </span>
                        </button>
                      </div> */}
                    </div>
                  </div>
                  {/* end:: filters for web screens */}
                  {/* begin:: filters for mobile screens */}
                  <div className="order__filters__mobile__screens reports__filters__mobile__screens">
                    <div className="col-12 d-xl-none">
                      <div className="form d-flex flex-column w-100">
                        <div className="filters__wrapper__mobile mb-3">
                          <div className="filters__toggle__mobile">
                            <button
                              type="button"
                              onClick={onReportsFilterToggle}
                              className={
                                "filters " +
                                (selectedKitchenId ||
                                selectedBrandId ||
                                dateTo ||
                                dateFrom
                                  ? "filter__applied"
                                  : "")
                              }
                            >
                              <span>
                                <img
                                  src={filterIcon}
                                  alt="export icon"
                                  className="img-fluid"
                                />
                              </span>
                            </button>
                          </div>
                          <div className="export__wrapper">
                            <button
                              className="export-btn btn"
                              onClick={exportReports}
                            >
                              <span>
                                <img
                                  src={exportIcon}
                                  alt="export icon"
                                  className="img-fluid icons"
                                />
                              </span>
                              <span>Export CSV</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end:: filters for mobile screens */}
                </div>
              </div>
              <section className="sections allrestaurants">
                <div className="row">
                  {/* <div className="section__heading"> */}
                  <div
                    className="section__heading col-12 d-flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h2>
                      {selectedKitchen ? selectedKitchen : "All Restaurants"}
                    </h2>
                    {(selectedKitchenId || (dateFrom && dateTo)) && (
                      <SelectedFilters
                        value={useFilters}
                        onSelect={onChangeSelection}
                      />
                    )}
                  </div>
                </div>
                {/* </div> */}
              </section>

              {reportData?.revenueData &&
              reportData?.revenueData?.length === 0 ? (
                <div className="managermodifier__noListing ">
                  <div className="managermodifier__noListing__innerwrapper">
                    <div className="content">
                      <div className="img-box">
                        <img
                          className="img-fluid"
                          src={emptyIocn}
                          alt="no list icon"
                          width="185"
                        />
                      </div>
                      <div className="content-desc">
                        <h3>No Reports Found</h3>
                      </div>
                    </div>
                  </div>
                </div>
              ) : isLoading ? (
                <LoaderBlock />
              ) : (
                <>
                  <section className="sections allrestaurants">
                    <div className="row">
                      <div className="col-12">
                        <SummarySection
                          data={reportData?.revenueData}
                          userRole={userRole}
                        />
                      </div>
                    </div>
                  </section>

                  <section className="sections reports__graph">
                    {/* graph chart will go here */}
                    <div className="reports__graph__wrapper">
                      <div className="reports__graph__wrapper__inner">
                        <div className="reports__graph__card">
                          <ChartSection
                            data={reportData?.reportDeliveryPartnersData}
                            type="order"
                          />
                        </div>
                        <div className="reports__graph__card">
                          <ChartSection
                            data={reportData?.reportMenuItemData}
                            type="items"
                          />
                        </div>
                      </div>
                    </div>
                  </section>

                  {reportData?.reportDeliveryPartnerRevenueData &&
                  reportData?.reportDeliveryPartnerRevenueData.length ? (
                    <section className="sections reportdetails">
                      <div className="row">
                        <div className="col-12">
                          <div className="section__heading">
                            <h2>Report Details</h2>
                          </div>
                        </div>
                        <div className="col-12">
                          {reportData?.reportDeliveryPartnerRevenueData?.map(
                            (report, idx) => (
                              <SummarySection data={report} key={idx} />
                            )
                          )}
                        </div>
                      </div>
                    </section>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <OrderFilterModal
          show={isOpen}
          className="right order__filters__modal"
          onHide={closeModal}
          filtersData={useFilters}
          title={"Filter Reports"}
          type={"reports"}
        />
      )}
    </>
  );
};

export default ReportLayout;
