import React from "react";

import { CmsPage } from "constants/cmsPages";
import { RoleType } from "constants/roleType";

import Home2 from "pages/home/Home2"
import Login from "pages/auth/login/Login";
import PasswordWrapper from "pages/auth/forget-password-wrapper/PasswordWrapper";
import VbList from "pages/dashboard/VbList";
import CreateVirtualBrand from "pages/create-brand-form/layout-wrapper/CreateVirtualBrand";
import page404 from "components/errorPages/page404";
import page500 from "components/errorPages/page500";
import noresultfond from "components/errorPages/noresultfond";
import Logout from "pages/auth/logout/Logout";
import ConfirmationPopUp from "components/confirmationPopUp/ConfirmationPopUp";
import Thankyou from "components/thankyouPage/Thankyou";
import ManageResturants from "components/ManageResturants/ManageResturants";
import ManageResturantsDetails from "components/ManageResturants/ManageResturantsDetails";
import ModifierListing from "pages/modifier/listing/ModifierListing";
import ModifierForm from "pages/modifier/modifier-form/ModifierForm";
import ModifierView from "pages/modifier/view/ModifierView";
import KitchenLayout from "pages/auth/kitchen-auth/layout-wrapper/KitchenLayout";
import AddnewmodifierModal from "components/addNewModifierModal/AddnewmodifierModal";
import NothingAdded from "components/noMenu&categories/NothingAdded";
import SignIn from "components/onboarding/SignIn";
import AboutBusiness from "components/onboarding/AboutBusiness";
import Resendmail from "components/onboarding/Resendmail";
import Congrats from "components/onboarding/Congrats";
import KitchenRegisterComplete from "pages/auth/kitchen-auth/kitchen-register-complete/KitchenRegisterComplete";
import RestaurantListing from "pages/restaurant/listing/RestaurantListing";
import RestaurantForm from "pages/restaurant/restaurant-form/RestaurantForm";
import RestaurantView from "pages/restaurant/view/RestaurantView";
import EditRestaurant from "components/ManageResturants/EditRestaurant";
import StaffListing from "pages/staff/listing/StaffListing";
import StaffForm from "pages/staff/staff-form/StaffForm";
import StaffView from "pages/staff/view/StaffView";
import ManageUserLandingPage from "components/ManageUser/ManageUserLandingPage";
import ManageUserDetails from "components/ManageUser/ManageUserDetails";
import AddUser from "components/ManageUser/AddUser";
import EditUser from "components/ManageUser/EditUser";
import ManageDevicesLandingPage from "components/ManageDevices/ManageDevicesLandingPage";
import ManageDeviceDetails from "components/ManageDevices/ManageDeviceDetails";
import AddDevice from "components/ManageDevices/AddDevice";
import MyAccount from "components/MyAccount/MyAccount";
import EditAccount from "components/MyAccount/EditAccount";
import ChangePassword from "components/MyAccount/ChangePassword";
import DeviceListing from "pages/device/listing/DeviceListing";
import DeviceForm from "pages/device/device-form/DeviceForm";
import DeviceView from "pages/device/view/DeviceView";
import MyProfile from "pages/profile/MyProfile";
import ChangeProfilePassword from "pages/profile/ChangeProfilePassword";
import EditProfile from "pages/profile/EditProfile";
import CommonCms from "pages/common-cms/CommonCms";
import Home from "pages/home/Home";
import OrdersLandingPage from "components/Orders/OrdersLandingPage";
import OrderDetails from "components/Orders/OrderDetails";
import LandingPage from "components/LandingPage/LandingPage";
import AddNewConnection from "components/Connections/AddNewConnection";
import EditConnection from "components/Connections/EditConnection";
import ConnectionForm from "pages/restaurant/view/connection/ConnectionForm";
import HowItWorks from "components/HowItWorks/HowItWorks";
import Terms from "components/Terms/Terms";
import FAQ from "components/FAQ/FAQ";
import OrderListing from "pages/order/listing/OrderListing";
import OrderView from "pages/order/view/OrderView";
import ContactUs from "components/ContactUs/ContactUs";
import Contact from "pages/contact/Contact";
import Reports from "components/Reports/Reports";
import Privacy from "components/Privacy/Privacy";
import ReportLayout from "pages/report/ReportLayout";
import SettingsLayout from "pages/settings/SettingsLayout";
import Security from "components/Security/Security";
import CreateRecipeGuide from "pages/recipeguide/layout-wrapper/CreateRecipeGuide";
import RecipeGuideListing from "pages/recipeguide/listing/RecipeGuideListing";
import RecipeListing from "pages/recipe/listing/RecipeListing";
import RecipeFormGlobal from "pages/recipe/recipe-form/RecipeFormGlobal";
import CreateRecipe from "pages/recipe/view/CreateRecipe";
import ManageCustomerListing from "pages/customer/listing/ManageCustomersListing";
import CustomerDetails from "pages/customer/view/CustomerDetails";
import ConsentPage from "pages/consent-auth/ConsentPage";

// const Login = React.lazy(() => import("pages/auth/login/Login"));
// const PasswordWrapper = React.lazy(() =>
//   import("pages/auth/forget-password-wrapper/PasswordWrapper")
// );
// const VbList = React.lazy(() => import("pages/dashboard/VbList"));
// const CreateVirtualBrand = React.lazy(() =>
//   import("pages/create-brand-form/layout-wrapper/CreateVirtualBrand")
// );
// const page404 = React.lazy(() => import("components/errorPages/page404"));
// const page500 = React.lazy(() => import("components/errorPages/page500"));
// const noresultfond = React.lazy(() =>
//   import("components/errorPages/noresultfond")
// );

// const Logout = React.lazy(() => import("pages/auth/logout/Logout"));
// const ConfirmationPopUp = React.lazy(() =>
//   import("components/confirmationPopUp/ConfirmationPopUp")
// );
// const Thankyou = React.lazy(() => import("components/thankyouPage/Thankyou"));
// const ManageResturants = React.lazy(() =>
//   import("components/ManageResturants/ManageResturants")
// );
// const ManageResturantsDetails = React.lazy(() =>
//   import("components/ManageResturants/ManageResturantsDetails")
// );
// const ModifierListing = React.lazy(() =>
//   import("pages/modifier/listing/ModifierListing")
// );
// const ModifierForm = React.lazy(() =>
//   import("pages/modifier/modifier-form/ModifierForm")
// );
// const ModifierView = React.lazy(() =>
//   import("pages/modifier/view/ModifierView")
// );
// const KitchenLayout = React.lazy(() =>
//   import("pages/auth/kitchen-auth/layout-wrapper/KitchenLayout")
// );
// const AddnewmodifierModal = React.lazy(() =>
//   import("components/addNewModifierModal/AddnewmodifierModal")
// );
// const NothingAdded = React.lazy(() =>
//   import("components/noMenu&categories/NothingAdded")
// );
// const SignIn = React.lazy(() => import("components/onboarding/SignIn"));
// const AboutBusiness = React.lazy(() =>
//   import("components/onboarding/AboutBusiness")
// );
// const Resendmail = React.lazy(() => import("components/onboarding/Resendmail"));
// const Congrats = React.lazy(() => import("components/onboarding/Congrats"));
// const KitchenRegisterComplete = React.lazy(() =>
//   import(
//     "pages/auth/kitchen-auth/kitchen-register-complete/KitchenRegisterComplete"
//   )
// );
// const RestaurantListing = React.lazy(() =>
//   import("pages/restaurant/listing/RestaurantListing")
// );
// const RestaurantForm = React.lazy(() =>
//   import("pages/restaurant/restaurant-form/RestaurantForm")
// );
// const RestaurantView = React.lazy(() =>
//   import("pages/restaurant/view/RestaurantView")
// );
// const EditRestaurant = React.lazy(() =>
//   import("components/ManageResturants/EditRestaurant")
// );
// const StaffListing = React.lazy(() =>
//   import("pages/staff/listing/StaffListing")
// );
// const StaffForm = React.lazy(() => import("pages/staff/staff-form/StaffForm"));
// const StaffView = React.lazy(() => import("pages/staff/view/StaffView"));
// const ManageUserLandingPage = React.lazy(() =>
//   import("components/ManageUser/ManageUserLandingPage")
// );
// const ManageUserDetails = React.lazy(() =>
//   import("components/ManageUser/ManageUserDetails")
// );
// const AddUser = React.lazy(() => import("components/ManageUser/AddUser"));
// const EditUser = React.lazy(() => import("components/ManageUser/EditUser"));
// const ManageDevicesLandingPage = React.lazy(() =>
//   import("components/ManageDevices/ManageDevicesLandingPage")
// );
// const ManageDeviceDetails = React.lazy(() =>
//   import("components/ManageDevices/ManageDeviceDetails")
// );
// const AddDevice = React.lazy(() =>
//   import("components/ManageDevices/AddDevice")
// );
// const MyAccount = React.lazy(() => import("components/MyAccount/MyAccount"));
// const EditAccount = React.lazy(() =>
//   import("components/MyAccount/EditAccount")
// );
// const ChangePassword = React.lazy(() =>
//   import("components/MyAccount/ChangePassword")
// );

// const DeviceListing = React.lazy(() =>
//   import("pages/device/listing/DeviceListing")
// );
// const DeviceForm = React.lazy(() =>
//   import("pages/device/device-form/DeviceForm")
// );
// const DeviceView = React.lazy(() => import("pages/device/view/DeviceView"));
// const MyProfile = React.lazy(() => import("pages/profile/MyProfile"));
// const ChangeProfilePassword = React.lazy(() =>
//   import("pages/profile/ChangeProfilePassword")
// );
// const EditProfile = React.lazy(() => import("pages/profile/EditProfile"));

// const CommonCms = React.lazy(() => import("pages/common-cms/CommonCms"));
// const Home = React.lazy(() => import("pages/home/Home"));
// const OrdersLandingPage = React.lazy(() =>
//   import("components/Orders/OrdersLandingPage")
// );
// const OrderDetails = React.lazy(() => import("components/Orders/OrderDetails"));
// const LandingPage = React.lazy(() =>
//   import("components/LandingPage/LandingPage")
// );
// const AddNewConnection = React.lazy(() =>
//   import("components/Connections/AddNewConnection")
// );
// const EditConnection = React.lazy(() =>
//   import("components/Connections/EditConnection")
// );
// const ConnectionForm = React.lazy(() =>
//   import("pages/restaurant/view/connection/ConnectionForm")
// );

// const HowItWorks = React.lazy(() => import("components/HowItWorks/HowItWorks"));
// const Terms = React.lazy(() => import("components/Terms/Terms"));
// const FAQ = React.lazy(() => import("components/FAQ/FAQ"));
// const OrderListing = React.lazy(() =>
//   import("pages/order/listing/OrderListing")
// );
// const OrderView = React.lazy(() => import("pages/order/view/OrderView"));
// const ContactUs = React.lazy(() => import("components/ContactUs/ContactUs"));
// const Contact = React.lazy(() => import("pages/contact/Contact"));
// const Reports = React.lazy(() => import("components/Reports/Reports"));
// const Privacy = React.lazy(() => import("components/Privacy/Privacy"));
// const ReportLayout = React.lazy(() => import("pages/report/ReportLayout"));
// const Security = React.lazy(() => import("components/Security/Security"));

const homeRoute = [
  {
    path: "/",
    component: Home2,
  },
  {
    path: "/home",
    component: Home2,
  },
  {
    path: "/landing",
    component: LandingPage,
  },

  {
    path: "/faq",
    component: FAQ,
    pageType: CmsPage.FAQ,
  },
  {
    path: "/how-it-works",
    component: HowItWorks,
    pageType: CmsPage.HELP,
  },
  {
    path: "/agreement",
    component: CommonCms,
    pageType: CmsPage.AGREEMENT,
  },
  {
    path: "/privacy-policy",
    component: Privacy,
    pageType: CmsPage.PRIVACY,
  },
  {
    path: "/terms-of-use",
    component: Terms,
    pageType: CmsPage.TERMS,
  },
  {
    path: "/contact",
    component: Contact,
    pageType: CmsPage.CALLBACK,
  },

  {
    path: "/security",
    component: Security,
    pageType: CmsPage.CALLBACK,
  },

  {
    path: "/how-it-works",
    component: HowItWorks,
  },
  {
    path: "/terms",
    component: Terms,
  },
  {
    path: "/faqs",
    component: FAQ,
  },
  {
    path: "/contact-us",
    component: ContactUs,
  },
  {
    path: "/recipeguide/:brandId/:recipeGuideId/:deviceTokenId",
    component: CreateRecipeGuide,
    accessor: [RoleType.KITCHEN_STAFF],
  },
  // {
  //   path: "/security",
  //   component: Security,
  // },
];

const authRoutes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/login/forget",
    component: PasswordWrapper,
  },
  {
    path: "/kitchen/register",
    component: KitchenLayout,
  },
  {
    path: "/kitchen/verify",
    component: KitchenRegisterComplete,
  },

  {
    path: "/confirm",
    component: ConfirmationPopUp,
  },
  {
    path: "/thankyou",
    component: Thankyou,
  },
  {
    path: "/manage-resturants",
    component: ManageResturants,
  },
  {
    path: "/resturants-details",
    component: ManageResturantsDetails,
  },
  {
    path: "/modifier-modal",
    component: AddnewmodifierModal,
  },
  {
    path: "/no-menu-added",
    component: NothingAdded,
  },
  {
    path: "/signIn",
    component: SignIn,
  },
  {
    path: "/about-business",
    component: AboutBusiness,
  },
  {
    path: "/resend-email",
    component: Resendmail,
  },
  {
    path: "/congrats",
    component: Congrats,
  },
  {
    path: "/edit-resturants",
    component: EditRestaurant,
  },
  { path: "/resturants-details", component: ManageResturantsDetails },
  { path: "/manage-user", component: ManageUserLandingPage },
  { path: "/user-details", component: ManageUserDetails },
  { path: "/add-user", component: AddUser },
  { path: "/edit-user", component: EditUser },
  { path: "/manage-devices", component: ManageDevicesLandingPage },
  { path: "/device-details", component: ManageDeviceDetails },
  { path: "/add-device", component: AddDevice },
  { path: "/account", component: MyAccount },
  { path: "/account-edit", component: EditAccount },
  { path: "/change-password", component: ChangePassword },
  { path: "/orders-list", component: OrdersLandingPage },
  { path: "/orders-details", component: OrderDetails },
  { path: "/add-connection", component: AddNewConnection },
  { path: "/edit-connection", component: EditConnection },
  { path: "/reports", component: Reports },
];

const privateRoutes = [
  {
    path: "/dashboard",
    component: VbList,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
      RoleType.AFFILIATE_USER,
    ],
  },
  {
    path: "/brand/add",
    component: CreateVirtualBrand,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
      RoleType.AFFILIATE_USER,
    ],
  },
  {
    path: "/recipeguide/add/:brandId/:recipeGuideId",
    component: CreateRecipeGuide,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/recipeguide/list/:brandId",
    component: RecipeGuideListing,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/recipeguide/:brandId/:recipeGuideId",
    component: CreateRecipeGuide,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/brand/edit/:brandId",
    component: CreateVirtualBrand,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/brand/:brandId",
    component: CreateVirtualBrand,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/brand/:brandId/shared/:kitchenId",
    component: CreateVirtualBrand,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/modifiers",
    component: ModifierListing,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/modifiers/list",
    component: ModifierListing,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/modifiers/add",
    component: ModifierForm,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/modifiers/:type/:modGroupId",
    component: ModifierForm,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/modifiers/edit/:modGroupId",
    component: ModifierForm,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/modifiers/:modGroupId",
    component: ModifierView,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/restaurants",
    component: RestaurantListing,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
    ],
  },
  {
    path: "/restaurants/list",
    component: RestaurantListing,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
    ],
  },
  {
    path: "/restaurants/:restId/edit",
    component: RestaurantForm,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
    ],
  },
  {
    path: "/restaurants/:restId",
    component: RestaurantView,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
    ],
  },
  {
    path: "/restaurants/:restId/connection/:kitchenId/add",
    component: ConnectionForm,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
    ],
  },
  {
    path: "/restaurants/:restId/connection/:connId/edit",
    component: ConnectionForm,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
    ],
  },
  {
    path: "/staff",
    component: StaffListing,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/staff/list",
    component: StaffListing,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/staff/add",
    component: StaffForm,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      // RoleType.KITCHEN_STAFF
    ],
  },
  {
    path: "/staff/:userId/edit",
    component: StaffForm,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/staff/:userId",
    component: StaffView,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/customer",
    component: ManageCustomerListing,
    accessor: [RoleType.ADMIN, RoleType.KITCHEN_OWNER, RoleType.ADMIN_STAFF],
  },
  {
    path: "/customer/list",
    component: ManageCustomerListing,
    accessor: [RoleType.ADMIN, RoleType.KITCHEN_OWNER, RoleType.ADMIN_STAFF],
  },
  {
    path: "/customer/:customerId",
    component: CustomerDetails,
    accessor: [RoleType.ADMIN, RoleType.KITCHEN_OWNER, RoleType.ADMIN_STAFF],
  },
  {
    path: "/devices",
    component: DeviceListing,
    accessor: [RoleType.ADMIN, RoleType.ADMIN_STAFF],
  },
  {
    path: "/device/list",
    component: DeviceListing,
    accessor: [RoleType.ADMIN, RoleType.ADMIN_STAFF],
  },
  {
    path: "/device/add",
    component: DeviceForm,
    accessor: [RoleType.ADMIN, RoleType.ADMIN_STAFF],
  },
  {
    path: "/device/:deviceId/edit",
    component: DeviceForm,
    accessor: [RoleType.ADMIN, RoleType.ADMIN_STAFF],
  },
  {
    path: "/device/:deviceId",
    component: DeviceView,
    accessor: [RoleType.ADMIN, RoleType.ADMIN_STAFF],
  },
  {
    path: "/recipes",
    component: RecipeListing,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/recipe/list",
    component: RecipeListing,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/recipe/add",
    component: RecipeFormGlobal,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/recipe/edit/:recipeId",
    component: RecipeFormGlobal,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/recipe/:recipeId",
    component: CreateRecipe,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/orders",
    component: OrderListing,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },
  {
    path: "/order/:orderId",
    component: OrderView,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
    ],
  },

  {
    path: "/profile",
    component: MyProfile,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
      RoleType.AFFILIATE_USER,
    ],
  },
  {
    path: "/profile/change-password",
    component: ChangeProfilePassword,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
      RoleType.AFFILIATE_USER,
    ],
  },
  {
    path: "/profile/edit",
    component: EditProfile,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
      RoleType.AFFILIATE_USER,
    ],
  },
  {
    path: "/report",
    component: ReportLayout,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.BRAND_OWNER,
      RoleType.AFFILIATE_USER,
    ],
  },
  {
    path: "/settings",
    component: SettingsLayout,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      // RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
      RoleType.AFFILIATE_USER,
    ],
  },
  {
    path: "/logout",
    component: Logout,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
      RoleType.AFFILIATE_USER,
    ],
  },
  {
    path: "/consent",
    component: ConsentPage,
    accessor: [
      RoleType.ADMIN,
      RoleType.KITCHEN_OWNER,
      RoleType.ADMIN_STAFF,
      RoleType.KITCHEN_STAFF,
      RoleType.BRAND_OWNER,
      RoleType.AFFILIATE_USER,
    ],
  },

  {
    path: "/404",
    component: page404,
  },
  {
    path: "/500",
    component: page500,
  },
  {
    path: "/noresultfound",
    component: noresultfond,
  },
];

export { privateRoutes, authRoutes, homeRoute };
